import React from 'react'
import { useState, useEffect } from 'react';
import GlobalFunctions from '../../services/GlobalFunctions'
import BotonPagos from '../GeneralUI/BotonPagos'

const TablaPrestamo = (params) => {

  const glob = new GlobalFunctions()
  const [cuotasMora, setCuotasMora] = useState(0)
  const [abonos, setAbonos] = useState([])

  useEffect(() => {
    if (abonos.length !== params.datosAbonos.length) {
      setAbonos(params.datosAbonos)
    }
    if (params.cuotasMora !== cuotasMora) {
      setCuotasMora(params.cuotasMora)
    }
    try {
      let numero = parseFloat(cuotasMora)
      if (numero >= 1) {
        document.getElementById('casillaCuotasMora').style.background = '#FC5656'
      }
    }
    catch (err) {
    }
  })

  function validarVencimiento(fecha) {
    let background = ''
    if (Date.parse(fecha) < Date.parse(glob.getFecha())) {
      background = '#fc5656'
    }
    return background
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-success">
          <tr>
            <th colSpan="4">Detalle cuenta</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan="4">{params.datosCliente[0].nombre}</th>
          </tr>
        </thead>
        <tbody>
          <tr className='align-middle'>
            <th colSpan="2" scope="row">Fecha {params.titulo}</th>
            <td colSpan="2">{params.datosPrest[0].fecha_prest}</td>
          </tr>
          <tr className='align-middle'>
            <th colSpan="2" scope="row">Valor {params.titulo}</th>
            <td colSpan="2">$ {glob.formatNumber(params.datosPrest[0].valorprestamo)}</td>
          </tr>
          <tr className='align-middle'>
            <th colSpan="2" scope="row">Valor cuotas</th>
            <td colSpan="2">$ {glob.formatNumber(params.datosPrest[0].valor_cuotas)}</td>
          </tr>
          <tr className='align-middle'>
            <th scope="row">N° cuotas</th>
            <td>{params.datosPrest[0].n_cuotas}</td>
            <th scope="row">Cuotas en mora</th>
            <td className='rounded' id='casillaCuotasMora' style={{ color: 'black', background: 'white' }} >{params.cuotasMora}</td>
          </tr>
          <tr className='align-middle'>
            <th colSpan="2" scope="row">Fecha Límite de pago</th>
            <td className='rounded' style={{ backgroundColor: validarVencimiento(params.datosPrest[0].vencimiento) }} colSpan="2">{params.datosPrest[0].vencimiento}</td>
          </tr>
          <tr className='align-middle'>
            <th colSpan="2" scope="row">Saldo</th>
            <td colSpan="2">$ {glob.formatNumber(params.datosPrest[0].tt_saldo)}</td>
          </tr>
        </tbody>
      </table>
      <BotonPagos fecha_prest={params.datosPrest[0].fecha_prest} datosCliente={params.datosCliente} saldoTotal={params.datosPrest[0].tt_saldo} valor_cuotas={params.datosPrest[0].valor_cuotas} url_redirect="https://financiera.tucasabonita.site/financiera/" fuente={params.fuente} nCredito="noaplica" />
      <table className="table">
        <thead className="table-success">
          <tr>
            <th colSpan="4">Detalle abonos</th>
          </tr>
          <tr className='align-middle'>
            <th >Fecha</th>
            <th >Abono</th>
            <th >Altura cuota</th>
            <th >Asesor</th>
          </tr>
        </thead>
        <tbody >
          {abonos.map((item, index) => {
            return (
              <tr className='align-middle' key={index}>
                <td >{item.fecha}</td>
                <td >{glob.formatNumber(item.valor_abono)}</td>
                <td >{item.altura_cuota}</td>
                <td >{item.asesor}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaPrestamo