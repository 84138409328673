import React from 'react'
import loginLogo from '../../Images/login_logo.png'
import requestLogo from '../../Images/request_logo.png'

const GeneralMenu = (params) => {

    function validarMostrarHome() {
        let actualUrl = window.location.href
        let mostrar = 'none'
        if (actualUrl.includes('request')) {
            mostrar = ''
        }
        return mostrar
    }

    function validarMostrarRequest() {
        let actualUrl = window.location.href
        let mostrar = ''
        if (actualUrl.includes('request')) {
            mostrar = 'none'
        }
        return mostrar
    }


    return (
        <div style={{ textAlign: 'center' }} className="container">
            <div className="row justify-content-center" >
                <div style={{ marginBottom: '1em', display: validarMostrarHome() }} className="col-lg-3 col-md-3 col-sm-6 col-6"  >
                    <div onClick={params.goHome} className="card border border-primary card-flyer pointer">
                        <img style={{ width: '40%', height: 'auto', marginTop: '1em' }} src="https://tucasabonita.site/Imagenes_config/ico_app_foreground.png" className="card-img-top img-fluid centerImg" alt="" />
                        <div style={{ textAlign: 'center' }} className="card-body">
                            <h2 className="card-title superTitulo">Inicio</h2>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '1em' }} className="col-lg-3 col-md-3 col-sm-6 col-6"  >
                    <div onClick={params.validarGoMiCuenta} className="card border border-primary card-flyer pointer">
                        <img style={{ width: '40%', height: 'auto', marginTop: '1em' }} src={loginLogo} className="card-img-top img-fluid centerImg" alt="" />
                        <div style={{ textAlign: 'center' }} className="card-body">
                            <h2 className="card-title superTitulo">Mi cuenta</h2>
                        </div>
                    </div>
                </div>
                <div onClick={params.goSolicitudes} style={{ marginBottom: '1em', display: validarMostrarRequest() }} className="col-lg-3 col-md-3 col-sm-6 col-6"  >
                    <div className="card border border-primary card-flyer pointer">
                        <img style={{ width: '40%', height: 'auto', marginTop: '1em' }} src={requestLogo} className="card-img-top img-fluid centerImg" alt="" />
                        <div style={{ textAlign: 'center' }} className="card-body">
                            <h2 className="card-title superTitulo">Solicitudes</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralMenu